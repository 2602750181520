import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import OpenRoute from "./components/core/Auth/OpenRoute";
import { useState } from "react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import VerifyEmail from "./pages/VerifyEmail";
import About from "./pages/About";
import Contact from "./pages/Contact";
import MyProfile from "./components/core/Dashboard/MyProfile";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import Error from "./pages/Error";
import Settings from "./components/core/Dashboard/Settings";
import { useSelector } from "react-redux";
import EnrolledCourses from "./components/core/Dashboard/EnrolledCourses";
import Cart from "./components/core/Dashboard/Cart";
import { ACCOUNT_TYPE } from "./ultilites/constants";
import AddCourse from "./components/core/Dashboard/AddCourse";
import MyCourses from "./components/core/Dashboard/MyCourses";
import EditCourse from "./components/core/Dashboard/EditCourse";
import Catalog from "./pages/Catalog";
import CourseDetails from "./pages/CourseDetails";
import ViewCourse from "./pages/ViewCourse";
import VideoDetails from "./components/core/ViewCourse/VideoDetails";
import Instructor from "./components/core/Dashboard/InstructorDashboard/Instructor";
import AdminDash from "./components/core/Dashboard/adminDashboad/AdminDash";
import AdminCourses from "./components/core/Dashboard/admincourse/AdminCourses";
import UserAdmin from "./components/core/Dashboard/userDashboad/UserAdmin";
import CreateAccount from "./components/core/Dashboard/userDashboad/accountcreatefrom/CreateAccount";
import CreateCategory from "./components/core/Dashboard/userDashboad/accountcreatefrom/CreateCategory";
import CreateBlog from "./components/core/Dashboard/userDashboad/accountcreatefrom/CreateBlog";
import SignupAdmin from "./components/core/Dashboard/userDashboad/SignupAdmin";
import ParticlesComponent from "./components/particle/Particle";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import RefundPolicy from "./pages/RefundPolicy";
import Terms from "./pages/Terms";
import PrivacyandPolicy from "./pages/PrivacyandPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import Events from "./pages/Events";
import EventForm from "./components/core/Dashboard/Eventscreate/EventsForm";
import EventDetail from "./pages/EventDetail";
import ScrollToTopOrBottom from "./components/ScrollToTopOrBottom";
import SocialMediaToggle from "./components/SocialMediaToggle";
import InterHome from "./components/core/Dashboard/intership/InterHome";
import InternDashboad from "./pages/InternDashboad";

// Import new components
import Intersidbar from "./components/core/Dashboard/intership/Interndashboad/Intersidbar";
import ProjectDashboard from "./components/core/Dashboard/intership/Interndashboad/ProjectDashboard";
import ProjectDetails from "./components/core/Dashboard/intership/Interndashboad/ProjectDetail";
import ProjectBoard from "./components/core/Dashboard/intership/Interndashboad/ProjectBoard";
import OfferLetter from "./components/core/Dashboard/intership/Interndashboad/OfferLetter";
import SubmitProject from "./components/core/Dashboard/intership/Interndashboad/SubmitProject";
import LeaveProject from "./components/core/Dashboard/intership/Interndashboad/LeaveProject";
import Logout from "./components/core/Dashboard/intership/Interndashboad/Logout";
import FormInter from "./components/core/Dashboard/ADMININTERN/FormInter";
import InternFormComponent from "./components/core/Dashboard/intership/Interndashboad/components/InternFormComponent.jsx";
import InternprojectList from "./components/core/Dashboard/ADMININTERN/form list/InternprojectList";
import CertificatePage from "./components/core/Dashboard/intership/certificate/CertificatePage";
import WhatsAppButton from "./components/common/WhatsappButton.js"


// funtions start links
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { user } = useSelector((state) => state.profile);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
    document.documentElement.classList.toggle("dark", !darkMode);
  };

  return (
    <div className={`w-screen flex flex-col font-inter relative  `}>
      <ScrollToTopOrBottom />
      {/* Particle Component with no background */}
      <div className="absolute inset-0 -z-10">
        <ParticlesComponent darkMode={darkMode} />
      </div>
      <SocialMediaToggle />
      <div className="fixed top-0  inset-x-0 z-50 w-full ">
        <Navbar />
      </div>
      <ScrollToTop />

      <div className="mt-16">
        <Routes >

          <Route path="/" element={<Home />} />
          <Route path="catalog/:catalogName" element={<Catalog />} />
          <Route path="courses/:courseId" element={<CourseDetails />} />

          <Route
            path="signup"
            element={
              <OpenRoute>
                <Signup />
              </OpenRoute>
            }
          />
          <Route
            path="login"
            element={
              <OpenRoute>
                <Login />
              </OpenRoute>
            }
          />
          <Route
            path="forgot-password"
            element={
              <OpenRoute>
                <ForgotPassword />
              </OpenRoute>
            }
          />
          <Route
            path="verify-email"
            element={
              <OpenRoute>
                <VerifyEmail />
              </OpenRoute>
            }
          />
          <Route
            path="update-password/:id"
            element={
              <OpenRoute>
                <UpdatePassword />
              </OpenRoute>
            }
          />

          <Route path="/about" element={<About />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:eventId" element={<EventDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />

          <Route
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route path="dashboard/my-profile" element={<MyProfile />} />
            <Route path="dashboard/Settings" element={<Settings />} />
            {user?.accountType === ACCOUNT_TYPE.STUDENT && (
              <>
                <Route path="dashboard/cart" element={<Cart />} />
                <Route
                  path="dashboard/enrolled-courses"
                  element={<EnrolledCourses />}
                />
                <Route path="dashboard/internshipportal" element={<InterHome />} />
              </>
            )}

            {user?.accountType === ACCOUNT_TYPE.INSTRUCTOR && (
              <>
                <Route path="dashboard/instructor" element={<Instructor />} />
                <Route path="dashboard/add-course" element={<AddCourse />} />
                <Route path="dashboard/my-courses" element={<MyCourses />} />
                <Route
                  path="dashboard/edit-course/:courseId"
                  element={<EditCourse />}
                />
              </>
            )}

            {user?.accountType === ACCOUNT_TYPE.ADMIN && (
              <>
                <Route path="dashboard/admin" element={<AdminDash />} />
                <Route path="dashboard/admin-courses" element={<AdminCourses />} />
                <Route path="dashboard/my-courses" element={<MyCourses />} />
                <Route
                  path="dashboard/edit-course/:courseId"
                  element={<EditCourse />}
                />
                <Route path="dashboard/users-admin" element={<UserAdmin />} />
                <Route path="SignupAdmin" element={<SignupAdmin />} />
                <Route path="CreateCategory" element={<CreateCategory />} />
                <Route path="CreateBlog" element={<CreateBlog />} />
                <Route path="eventcreate" element={<EventForm />} />
                <Route path="adminintern" element={<FormInter />} />
                <Route path="interprojectlist" element={<InternprojectList />} />
                <Route
                  path="SignupAdmin/verify-email"
                  element={<VerifyEmail />}
                />
              </>
            )}
          </Route>

          {/* Use InternDashboad as the parent layout */}
          <Route path="/intern" element={<InternDashboad />}>
            <Route path="interndashboard" element={<ProjectDashboard />} />
            <Route path="projectdetails/:id" element={<ProjectDetails />} />
            <Route path="projectboard" element={<ProjectBoard />} />
            <Route path="offerletter" element={<OfferLetter />} />
            <Route path="submitproject" element={<SubmitProject />} />
            <Route path="submitproject/:projectId" element={<InternFormComponent />} />
            <Route path="leaveproject" element={<LeaveProject />} />
            <Route path="logout" element={<Logout />} />
            <Route path="certificate" element={<CertificatePage />} />
          </Route>

          <Route
            element={
              <PrivateRoute>
                <ViewCourse />
              </PrivateRoute>
            }
          >
            {user?.accountType === ACCOUNT_TYPE.STUDENT && (
              <>
                <Route
                  path="view-course/:courseId/section/:sectionId/sub-section/:subSectionId"
                  element={<VideoDetails />}
                />
              </>
            )}
          </Route>

          <Route path="*" element={<Error />} />
        </Routes>
      </div>

      {/* Toggle Button */}
      <button
        onClick={toggleDarkMode}
        className="fixed bottom-4  z-50 left-4 w-12 h-12 bg-white dark:bg-gray-800 text-black dark:text-white rounded-full shadow-lg flex items-center justify-center focus:outline-none"
      >
        {darkMode ? "🌙" : "☀️"}
      </button>

      <WhatsAppButton/>
    </div>
  );
}

export default App;
