import React from "react";
// import FoundingStory from "../assets/Images/group.jpg";
// import BannerImage1 from "../assets/Images/aboutus1.png";
// import BannerImage2 from "../assets/Images/aboutus2.png";
// import BannerImage3 from "../assets/Images/aboutus3.png";
import Footer from "../components/common/Footer";
import ContactFormSection from "../components/core/AboutPage/ContactForm";
import LearningGrid from "../components/core/AboutPage/LearningGrid";
import Quote from "../components/core/AboutPage/Quote";
import StatsComponenet from "../components/core/AboutPage/Stats";
// import ReviewSlider from "../components/common/ReviewSlider";

const About = () => {
  return (
    <div>
      <section className="bg-[#CD1412] ">
        <div className="relative mx-auto flex w-11/12 max-w-maxContent flex-col justify-between lg:gap-10 gap-10 text-center text-white">
        <header className="relative top-4 mx-auto lg:py-20 py-7 text-4xl font-semibold lg:w-[70%] text-white">

            Empowering the Future through
            <p className="bg-gradient-to-b from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB] text-transparent bg-clip-text text-4xl font-semibold ">
              AI and Innovation{" "}
            </p>
            <p className="mx-auto mt-3 text-center text-base font-medium text-richblack-5 lg:w-[95%]">
              At IDC India, we are driven by the vision of transforming
              education and fostering innovation. As a leading ed-tech startup,
              our mission is to build the world's largest AI e-learning
              platform, equipping millions with the skills needed to thrive in
              the digital age.
            </p>
          </header>
          <div className="sm:h-[70px] lg:h-[150px] lg:mt-20 mt-10"></div>
          {/* <div className="absolute bottom-0 left-[50%] grid w-[100%] translate-x-[-50%] lg:translate-y-[35%] translate-y-[50%] grid-cols-3 gap-3 lg:gap-5 ">
            <img src={BannerImage1} alt="" className="rounded-xl" />
            <img src={BannerImage2} alt="" className="rounded-xl" />
            <img src={BannerImage3} alt="" className="rounded-xl" />
          </div> */}
        </div>
      </section>

      <section className="border-b border-richblack-700">
        <div className="mx-auto flex w-11/12 max-w-maxContent lg:mt-5 flex-col justify-between lg:gap-10  text-richblack-500">
          <div className="h-[60px] mt-10"></div>
          <Quote />
        </div>
      </section>

      <section className="flex justify-center">
        <div className="mx-auto flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-500 text-alogn">
          <div className="relative mx-auto flex w-11/12 max-w-maxContent flex-col items-center text-center gap-10">
            <div className="lg:my-24 mt-5 flex lg:w-[80%] flex-col gap-10">
              <h1 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent">
                Our Founding Story
              </h1>
              <p className=" font-medium text-richblack-300 lg:w-[95%] text-2xl">
                Our e-learning platform was born out of a shared vision and
                passion for transforming education. It all began with a group of
                educators, technologists, and lifelong learners who recognized
                the need for accessible, flexible, and high-quality learning
                opportunities in a rapidly evolving digital world.
              </p>
              <p className=" font-medium text-richblack-300 lg:w-[95%] text-2xl">
                As experienced educators ourselves, we witnessed firsthand the
                limitations and challenges of traditional education systems. We
                believed that education should not be confined to the walls of a
                classroom or restricted by geographical boundaries. We
                envisioned a platform that could bridge these gaps and empower
                individuals from all walks of life to unlock their full
                potential.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center lg:gap-10 lg:flex-row justify-between">
            <div className="lg:my-24 flex lg:w-[40%] flex-col lg:gap-10 gap-4">
              <h1 className="bg-gradient-to-b from-[#FF512F] to-[#F09819] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%] ">
                Our Vision
              </h1>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                With this vision in mind, we set out on a journey to create an
                e-learning platform that would revolutionize the way people
                learn. Our team of dedicated experts worked tirelessly to
                develop a robust and intuitive platform that combines
                cutting-edge technology with engaging content, fostering a
                dynamic and interactive learning experience.
              </p>
            </div>
            <div className="lg:my-24 mt-9 flex lg:w-[40%] flex-col lg:gap-10 gap-4">
              <h1 className="bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%]  ">
                Our Mission
              </h1>
              <p className="text-base font-medium text-richblack-300 lg:w-[95%]">
                Our mission is to democratize access to high-quality education
                and training, empowering individuals to unlock their potential
                and contribute meaningfully to the world. By leveraging
                cutting-edge technology and world-class curricula, we aim to
                create a $100B Al-enabled Tech learning and Development Company
                Reviews creation learning company that shapes the future of
                learning and work.flexible, and high-quality learning
                opportunities in a rapidly evolving digital world.
              </p>
            </div>
          </div>
        </div>
      </section>

      <StatsComponenet />
      <section className="mx-auto mt-20 flex w-11/12 max-w-maxContent flex-col justify-between gap-10 text-richblack-700 ">
        <LearningGrid />
        <ContactFormSection />
      </section>

      {/* <div className="relative mx-auto my-20 flex w-11/12 max-w-maxContent flex-col items-center overflow-hidden justify-between gap-8 bg-richblack-900 text-white">

        <h1 className="text-center text-4xl font-semibold mt-8">
          Reviews from other learners
        </h1>
        <ReviewSlider />
      </div> */}
      <Footer />
    </div>
  );
};

export default About;
