import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Ensure this package is installed

const WhatsAppButton = () => {
  const phoneNumber = "+917986957055"; // Replace with your WhatsApp number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 z-50 bg-[#6F9C47] text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-green-600 transition duration-300"
    >
      <FaWhatsapp size={44} />
    </a>
  );
};

export default WhatsAppButton; 
