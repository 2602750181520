// src/components/CourseSwiper.js
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import { getAllCourses } from '../../../services/operations/courseDetailsAPI'; // Adjust the path based on your project structure
import { useNavigate } from 'react-router-dom';

const CourseAll = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      const data = await getAllCourses();
      // console.log('Fetched Courses:', data); // Log the courses data
      setCourses(data);
    };

    fetchCourses();
  }, []);

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const calculateAverageRating = (ratings) => {
    if (!Array.isArray(ratings) || ratings.length === 0) return 0;
    const sum = ratings.reduce((acc, review) => acc + (Number(review.rating) || 0), 0);
    return (sum / ratings.length).toFixed(1);  // Fixed to return 1 decimal place
  };

  return (
    <div className="course-swiper mx-4 my-8">
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        autoplay={{ delay: 2500 }}
        loop={true}
        className="swiper-container"
        pagination={{
          clickable: true,
          el: '.course-pagination',
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {courses.map((course) => {
          const averageRating = calculateAverageRating(course.ratingAndReviews);
          return (
            <SwiperSlide key={course._id} onClick={() => handleCardClick(course._id)}>
              <div className="course-card p-4 bg-richblack-400 hover:bg-richblack-800 rounded-lg shadow-lg cursor-pointer transform transition-transform duration-300 hover:scale-105 dark:bg-richblack-100 dark:text-richblack-800 dark:hover:bg-richblack-300">
                <img src={course.thumbnail} alt={course.courseName} className="h-[250px] w-full object-cover rounded-t-lg" />
                <div className="course-info p-4">
                  <h3 className="text-xl font-bold mb-2 text-white dark:text-richblack-700">{course.courseName}</h3>
                  <p className="text-gray-700 mb-2 line-clamp-3 text-white dark:text-richblack-700">{course.courseDescription}</p>
                  <p className="text-gray-900 font-semibold text-white dark:text-richblack-700">Price: ₹{course.price}</p>
                  {/* <div className="flex items-center mt-2">
                    <div className="flex">
                      {[...Array(5)].map((_, index) => (
                        <svg
                          key={index}
                          className={`w-5 h-5 ${
                            index < Math.floor(averageRating) ? 'text-yellow-100' 
                            : (index < averageRating ? 'text-yellow-100' : 'text-richblack-100')
                          }`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.357 4.181a1 1 0 00.95.69h4.382c.969 0 1.371 
                          1.24.588 1.81l-3.548 2.576a1 1 0 00-.364 1.118l1.357 4.182c.3.921-.755 1.688-1.54 1.118L10 15.347l-3.548 
                          2.576c-.784.57-1.839-.197-1.54-1.118l1.357-4.182a1 1 0 00-.364-1.118L2.357 9.608c-.783-.57-.38-1.81.588-1.81h4.382a1
                          1 0 00.95-.69l1.357-4.181z" />
                        </svg>
                      ))}
                    </div>
                    <p className="text-yellow-25 ml-2">
                      {averageRating} ({course.ratingAndReviews.length})
                    </p>
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="mt-20 flex justify-center gap-3">
        <div className="course-pagination flex justify-center gap-3"></div>
      </div>
    </div>
  );
};

export default CourseAll;
