import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import HighlightText from "../components/core/HomePage/HighlightText";
import CTAButton from "../components/core/HomePage/Button";
import Banner from "../assets/Images/Banner1try.mp4";
import CodeBlocks from "../components/core/HomePage/CodeBlocks";
import TimelineSection from "../components/core/HomePage/TimelineSection";
import LearingLanguageSection from "../components/core/HomePage/LearingLanguageSection";
import InstructionSection from "../components/core/HomePage/InstructionSec";
import Footer from "../components/common/Footer";
import ExploreMore from "../components/core/HomePage/ExploreMore";
import ReviewSlider from "../components/common/ReviewSlider";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import CourseSlider from "../components/core/Catalog/CourseSlider";
import { useInView } from "react-intersection-observer";
import TeacherSection from "../components/core/HomePage/TeacherSection";
import TeamSection from "../components/core/HomePage/TeamSection";
import CourseAll from "../components/core/HomePage/CourseAll";
import ParticlesComponent from "../components/particle/Particle";

const Home = () => {
  const [marqueeLoaded, setMarqueeLoaded] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMarqueeLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true, // Animate only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  // const [catalogPageData, setCatalogPageData] = useState(null);
  // const [categoryId, setCategoryId] = useState("");

  return (
    <div>
      {/*Section1  */}
      <div
        className="relative mx-auto flex flex-col w-11/12 max-w-maxContent items-center
      text-white dark:text-white justify-between"
      >
        {/* <Link to={"/contact"}>
          <motion.div
            className=' group mt-10 p-1 mx-auto rounded-full bg-richblack-700 font-bold text-white 
                transition-all duration-200 hover:scale-95 w-fit
                hover:shadow-[0px_0px_50px_-10px_grey]'>
            <div className='flex lg:flex-row items-center gap-2 rounded-full lg:px-10 lg:py-[5px] ml-10 md:text-center
                transition-all duration-200   hover:scale-110 '>
              <p>
                Become an Instructor
              </p>
              <FaArrowRight />
            </div>
          </motion.div>

        </Link> */}
        <div className="w-full">
          {/* {!marqueeLoaded && (
            <div className="loader">Loading...</div>
          )} */}
          <Link to="/events">
            <div
              className={`h-10 mt-5 flex items-center text-center rounded-2xl overflow-hidden bg-richblack-25 dark:bg-yellow-5 ${
                marqueeLoaded ? "" : "hidden"
              }`}
            >
              <div className="inline-block animate-marquee text-[#CD1412] text-2xl font-bold whitespace-nowrap no-scrollbar">
                78% Discount on this 78th Independence Day on all our courses .
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Get 78% OFF on
                the AI Explorers Program 2025—Join the AI revolution today!
              </div>
            </div>
          </Link>
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className="text-center text-4xl font-semibold mt-7 text-richblack-600 dark:text-white"
        >
          Building World's Largest
          <HighlightText text={" AI E-Learning Platform"} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className=" mt-4 w-[90%] text-center text-lg font-bold text-richblack-300 dark:text-richblack-25"
        >
          Our expert educators bring invaluable real-world knowledge and
          practical experience to enrich your learning journey, ensuring you
          gain the skills and insights needed to succeed in your chosen field.
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className="flex flex-row gap-7 mt-8"
        >
          <CTAButton active={true} linkto={"catalog/fullstack"}>
            Learn More
          </CTAButton>

          <CTAButton active={false} linkto={"contact"}>
            Enroll Now
          </CTAButton>
        </motion.div>

        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={sectionVariants}
          className="mx-3 my-12 w-[80%] dark:shadow-[0px_0px_30px_-5px_white]
                 rounded-2xl shadow-[0px_0px_30px_-10px_#1a202c] "
        >
          <video muted loop autoPlay className="rounded-2xl overflow-hidden">
            <source src={Banner} type="video/mp4" />
          </video>
        </motion.div>

        {/* slider  */}
        <div className="homepage w-screen  ">
          <div className="flex justify-center text-center">
            <h1 className=" bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-5xl font-semibold text-transparent w-[70%]   underline">
              Welcome to Our Courses
            </h1>
          </div>
          <CourseAll />
        </div>

        {/* Code Section 1  */}
        <div className="border border-b-richblack-100 dark:border-none dark:text-white">
          <CodeBlocks
            position={"lg:flex-row"}
            heading={
              <div className="text-4xl font-semibold text-richblack-500 dark:text-white">
                Unlock your
                <HighlightText text={"coding potential"} /> with our online
                courses.
              </div>
            }
            subheading={
              "Our courses are designed and taught by industry experts who have years of experience in coding and are passionate about sharing their knowledge with you."
            }
            ctabtn1={{
              btnText: "Try it Yourself",
              link: "catalog/aritifical-intelligence",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              link: "contact",
              active: false,
            }}
            codeColor={"text-[#9bf7d8]"}
            codeblock={`<!DOCTYPE html>\n <html lang="en">\n<head>\n<title>This is myPage</title>\n</head>\n<body>\n<h1><a href="/">Header</a></h1>\n<nav> <a href="/one">One</a> <a href="/two">Two</a> <a href="/three">Three</a>\n</nav>\n</body>`}
            backgroundGradient={<div className="codeblock1 absolute"></div>}
          />
        </div>

        {/* Code Section 2 */}
        <div>
          <CodeBlocks
            position={"lg:flex-row-reverse"}
            heading={
              <div className="w-[100%] text-4xl font-semibold lg:w-[50%] text-richblack-500 dark:text-richblack-5">
                Start
                <HighlightText text={"coding in seconds"} />
              </div>
            }
            subheading={
              "Go ahead, give it a try. Our hands-on learning environment means you'll be writing real code from your very first lesson."
            }
            ctabtn1={{
              btnText: "Continue Lesson",
              link: "catalog/aritifical-intelligence",
              active: true,
            }}
            ctabtn2={{
              btnText: "Learn More",
              link: "about",
              active: false,
            }}
            codeColor={"text-[#fcfc2a]"}
            codeblock={`import React from "react";\n import CTAButton from "./Button";\nimport TypeAnimation from "react-type";\nimport { FaArrowRight } from "react-icons/fa";\n\nconst Home = () => {\nreturn (\n<div>Home</div>\n)\n}\nexport default Home;`}
            backgroundGradient={<div className="codeblock2 absolute"></div>}
          />
        </div>

        <div>
          <ExploreMore />
        </div>
      </div>

      {/*Section 2  */}
      <div className=" text-richblack-700">
        <div className="homepage_bg h-[310px]  bg-richblack-5">
          <div className="w-11/12 max-w-maxContent flex flex-col items-center justify-between gap-5 mx-auto">
            <div className="h-[200px]"></div>
            <div className="flex flex-row gap-7 text-white">
              <CTAButton active={true} linkto={"catalog/fullstack"}>
                <div className="flex  items-center gap-3">
                  Explore Our Courses
                  <FaArrowRight />
                </div>
              </CTAButton>
              <Link>
                <CTAButton active={false} linkto={"about"}>
                  <div>Learn More</div>
                </CTAButton>
              </Link>
            </div>
          </div>
        </div>

        <div className="w-11/12 max-w-maxContent flex flex-col items-center justify-center gap-7 lg:ml-12 ml-3">
          {/* Job that is in Demand - Section 1 */}
          <div className="mb-10  flex flex-col justify-between gap-7 lg:mt-20 lg:flex-row lg:gap-0">
            <div className="text-4xl font-semibold lg:w-[45%] ">
              <HighlightText
                text={"Revolutionize your Tech learning Journey"}
              />
            </div>
            <div className="flex flex-col items-start gap-10 lg:w-[40%]">
              <div className="text-[16px] dark:text-richblack-25 ">
                The modern IDC India is the dictates its own terms. Today, to be
                a competitive specialist requires more than professional skills.
              </div>
              <CTAButton active={true} linkto={"about"}>
                <div className="">Learn More</div>
              </CTAButton>
            </div>
          </div>

          <TimelineSection />
          {/* <TeacherSection /> */}

          <LearingLanguageSection />
          {/* <TeamSection /> */}
        </div>
      </div>

      {/* Section 3 */}
      <div className="w-11/12 max-w-maxContent flex flex-col items-center justify-between gap-5 mx-auto bg-richblack-900 text-white mb-14">
        <InstructionSection />
      </div>
      {/* <div className="flex flex-col justify-center items-center">
        <h2 className="text-center text-4xl font-semibold mt-10 dark:text-white">
          Review from other Learners
        </h2> */}

        {/* Review slider */}
        {/* <div className="flex  lg:justify-center w-full ">
          <ReviewSlider />
        </div> */}
      {/* </div> */}

      {/*Footer */}
      <Footer />
    </div>
  );
};

export default Home;
