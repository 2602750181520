export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Catalog",
    // path: '/catalog',
  },
  {
    title: "About Us",
    path: "/about",
  },
  {
    title: "Contact Us",
    path: "/contact",
  },
  {
    title: "Blogs",
    path: "/blogs",
  },
  // {
  //   title: "Events",
  //   path: "/events",
  // },
  
];